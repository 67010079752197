<template id="candidates-answer">
  <div class="text-box">
    <div class="game-card">
      <div class="row justify-content-center">
        <div class="col-auto mb-4">
          <span
            :class="[
              'result-icon',
              answer.IS_CORRECT ? 'result-icon--correct' : 'result-icon--fail',
            ]"
          ></span>
        </div>
        <div class="col-12">
          <p class="candidates__title text-center">
            {{ answer.IS_CORRECT ? "Верно!" : "Неверно!" }}
          </p>
        </div>
      </div>
      <div class="row justify-content-center mb-4">
        <div class="col-12 col-md-10">
          <p class="candidates__text text-center">{{ answer.ANSWER }}</p>
        </div>
      </div>
      <div class="row justify-content-center">
        <div>
          <button
            v-on:click="nextStep"
            class="btn btn-accent text-center w-100 justify-content-center"
            type="button"
          >
            Продолжить
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    answer: Object,
  },
  setup(props, context) {
    const nextStep = () => {
      context.emit("nextStep");
    };

    return { nextStep };
  },
};
</script>

<style scoped>
.text-box {
  padding: 3rem 0;
}

@media (max-width: 768px) {
  .text-box {
    width: 100%;
    margin: 0;
    background-color: #edf2f8;
    box-shadow: none;
    border-radius: 0;
  }
}
</style>