<template id="candidates-card">
  <div class="candidate" v-on:click="select">
    <div class="candidate__section">
      <div class="flex align-item-center">
        <div
          class="candidate__avatar"
          :style="{ backgroundImage: 'url(' + item.AVATAR + ')' }"
        ></div>
        <div class="candidate__name">{{ item.NAME }}</div>
      </div>
    </div>
    <div class="candidate__section">
      <div class="candidate__section-title">
        Ваш общий уровень эмоционального интеллекта
      </div>
      <card-property
        title="Общий балл ЭИ"
        :value="item.COMMON"
        :max-value="150"
      ></card-property>
    </div>
    <div class="candidate__section">
      <div class="candidate__section-title">
        Шкалы эмоционального интеллекта
      </div>
      <card-property
        title="Восприятие эмоций"
        :value="item.PRECEPTION"
        :max-value="150"
      ></card-property>
      <card-property
        title="Использование эмоций"
        :value="item.USING"
        :max-value="150"
      ></card-property>
      <card-property
        title="Понимание эмоций"
        :value="item.UNDERSTANDING"
        :max-value="150"
      ></card-property>
      <card-property
        title="Управление эмоциями"
        :value="item.CONTROL"
        :max-value="150"
      ></card-property>
    </div>
  </div>
</template>

<script>
import CardProperty from "./CardProperty.vue";
export default {
  components: { CardProperty },
  name: "card",
  props: {
    item: Object,
  },
  setup(props, context) {
    const select = () => {
      console.log(props.item);
      context.emit("selectCandidate", props.item);
    };

    return { select };
  },
};
</script>

<style scoped>
.candidate {
  position: relative;
  width: 250px;
  padding: 20px 15px 20px 15px;
  box-shadow: 0px 4px 20px rgba(182, 196, 214, 0.5);
  border-radius: 10px;
  background-color: #ffffff;
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  cursor: pointer;
}

.candidate:hover {
  box-shadow: 0px 4px 20px rgba(182, 196, 214, 1);

  -webkit-transform: scale3d(1.1, 1.1, 1.1);
  -ms-transform: scale3d(1.1, 1.1, 1.1);
  transform: scale3d(1.1, 1.1, 1.1);
}

@media (min-width: 768px) {
  .candidate {
    width: 300px;
    padding: 30px 20px 30px 20px;
  }
}

@media (min-width: 992px) {
  .candidate {
    width: 350px;
  }
}

.candidate__avatar {
  -ms-flex: 0 0 80px;
  flex: 0 0 80px;
  max-width: 80px;
  width: 80px;
  height: 80px;
  margin-right: 10px;
  border-radius: 50%;
  background-color: gray;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

@media (min-width: 768px) {
  .candidate__avatar {
    -ms-flex: 0 0 90px;
    flex: 0 0 90px;
    max-width: 90px;
    width: 90px;
    height: 90px;
  }
}

@media (min-width: 992px) {
  .candidate__avatar {
    -ms-flex: 0 0 120px;
    flex: 0 0 120px;
    max-width: 120px;
    width: 120px;
    height: 120px;
  }
}

.candidate__name {
  color: #2d2f40;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
}

@media (min-width: 768px) {
  .candidate__name {
    font-size: 18px;
  }
}
.candidate__section {
  margin: 0px 0px 0px 0px;
}

.candidate__section:not(:last-child) {
  margin-bottom: 20px;
}

.candidate__section-title {
  color: #55cb28;
  font-size: 12px;
  font-weight: 400;
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 10px 0px;
}

@media (min-width: 768px) {
  .candidate__section-title {
    font-size: 16px;
    margin-bottom: 15px;
  }
}
</style>