<template id="candidates-cards">
  <div class="text-box">
    <div class="title">
      <div class="col-12">
        <h2 class="candidates__title text-center">
          Кого бы Вы взяли на должность<br />
          <div class="position">"{{ position }}"?</div>
        </h2>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="row justify-content-center cards">
          <div
            class="col-auto p-2 p-lg-3"
            v-for="(item, index) in items"
            :key="index"
          >
            <candidates-card
              @selectCandidate="selectCandidate"
              :item="item"
            ></candidates-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CandidatesCard from "./CandidatesCard.vue";
export default {
  components: { CandidatesCard },
  props: {
    position: {
      type: String,
      default: "",
    },
    items: {
      default: [],
    },
  },
  setup(props, context) {
    const selectCandidate = (item) => {
      console.log(item);
      context.emit("selectCandidate", item);
    };
    return {
      selectCandidate,
    };
  },
};
</script>

<style scoped>
.candidates {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;

  padding-top: 40px;
  padding-bottom: 40px;
  border-radius: 10px;
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.3);
}

.position {
  font-weight: 500;
  display: inline-block;
}

.cards {
  gap: 40px;
}

@media (max-width: 768px) {
  .cards {
    gap: 0;
    flex-direction: column;
    align-items: center;
  }

  .candidates__title {
    font-size: 22px;
    margin-bottom: 0;
    padding: 0 10px;
  }
}

@media (min-width: 768px) {
  .candidates {
    padding-top: 0px;
    padding-bottom: 0px;
    height: 600px;
  }
}

@media (min-width: 992px) {
  .candidates {
    height: 700px;
  }
}
</style>