<template>
  <div id="candidates" class="candidates">
    <div class="container">
      <transition name="slide-right" mode="out-in">
        <candidates-start
          v-if="!gameRunning"
          @startGame="startGame"
          :positions="getListPosition"
        ></candidates-start>
        <candidates-finish
          @endGame="endGame"
          v-else-if="gameOver"
          :result="result"
        ></candidates-finish>
        <candidates-answer
          @nextStep="nextStep"
          v-else-if="showAnswer"
          :answer="getAnswer"
        ></candidates-answer>
        <candidates-cards
          v-else
          @selectCandidate="selectCandidate"
          :position="getCurrentPosition"
          :items="getCurrentCards"
        ></candidates-cards>
      </transition>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import CandidatesStart from "./CandidatesStart.vue";
import CandidatesFinish from "./CandidatesFinish.vue";
import CandidatesAnswer from "./CandidatesAnswer.vue";
import CandidatesCards from "./CandidatesCards.vue";

export default {
  components: {
    CandidatesStart,
    CandidatesFinish,
    CandidatesAnswer,
    CandidatesCards,
  },
  name: "game",
  setup() {
    const gameRunning = ref(false);
    const gameOver = ref(false);
    const showAnswer = ref(false);
    const data = ref([
      {
        POSITION: "Руководитель отдела продаж",
        ITEMS: [
          {
            AVATAR:
              "https://crm-upload.storage.yandexcloud.net/iblock/844/844e0a7d43015ab3573ac120dcb1e108/Kolobkov.jpg",
            NAME: "Виталий Григорьевич Колобков",
            COMMON: "138",
            PRECEPTION: "142",
            USING: "122",
            UNDERSTANDING: "95",
            CONTROL: "113",
            IS_CORRECT: true,
            ANSWER:
              "Для руководителя отдела продаж важно уметь хорошо считывать эмоции окружающих и уметь направлять свои и чужие эмоции для решения поставленных задач. ",
          },
          {
            AVATAR:
              "https://crm-upload.storage.yandexcloud.net/iblock/563/563ebc27828e87566e0a49d57890eddc/Topol.jpg",
            NAME: "Анна Алексеевна Тополь",
            COMMON: "110",
            PRECEPTION: "106",
            USING: "102",
            UNDERSTANDING: "120",
            CONTROL: "104",
            IS_CORRECT: false,
            ANSWER:
              "Для руководителя отдела продаж важно уметь хорошо считывать эмоции окружающих и уметь направлять свои и чужие эмоции для решения поставленных задач. Данному кандидату лучше дается анализ эмоций.",
          },
        ],
      },
      {
        POSITION: "Главный бухгалтер",
        ITEMS: [
          {
            AVATAR:
              "https://crm-upload.storage.yandexcloud.net/iblock/d1e/d1e033f88d2994ce2d6acddd9aa4c88f/Artisova.jpg",
            NAME: "Ольга Петровна Артисова",
            COMMON: "110",
            PRECEPTION: "106",
            USING: "102",
            UNDERSTANDING: "120",
            CONTROL: "104",
            IS_CORRECT: true,
            ANSWER:
              "Для главного бухгалтера важно понимать и анализировать информацию, в том числе и информацию, которую он получает через эмоции, как свои, так и других людей. Человеку с высокими значениями по ветви «Понимание эмоций» подходит должность, связанная с анализом. ",
          },
          {
            AVATAR:
              "https://crm-upload.storage.yandexcloud.net/iblock/2a0/2a029190043d1a245470faaffdc816cb/SHumanova.jpg",
            NAME: "Ирина Андреевна Шуманова",
            COMMON: "117",
            PRECEPTION: "117",
            USING: "118",
            UNDERSTANDING: "87",
            CONTROL: "114",
            IS_CORRECT: false,
            ANSWER:
              "Для главного бухгалтера важно понимать и анализировать информацию, в том числе и информацию, которую он получает через эмоции, как свои, так и других людей. Данному кандидату анализ дается не так хорошо, как другие процессы, и, несмотря на высокий балл по общему ЭИ, ему лучше подойдет какая-нибудь другая руководящая должность. ",
          },
        ],
      },
      {
        POSITION: "Руководитель производства",
        ITEMS: [
          {
            AVATAR:
              "https://crm-upload.storage.yandexcloud.net/iblock/717/71716da897766110fabb60b7c7e1d222/Goldman.jpg",
            NAME: "Геннадий Альбертович Гольдман",
            COMMON: "117",
            PRECEPTION: "117",
            USING: "118",
            UNDERSTANDING: "87",
            CONTROL: "114",
            IS_CORRECT: true,
            ANSWER:
              "Для руководителя производства важно использовать свои эмоции и рационально расходовать ресурсы. Человеку с высокими значениями по ветви «Использование эмоций» подходит руководящая должность.",
          },
          {
            AVATAR:
              "https://crm-upload.storage.yandexcloud.net/iblock/45b/45b553d6e67b246ab7deff954115b16a/Pochichuev.jpg",
            NAME: "Владимир Сергеевич Почичуев",
            COMMON: "110",
            PRECEPTION: "122",
            USING: "96",
            UNDERSTANDING: "96",
            CONTROL: "118",
            IS_CORRECT: false,
            ANSWER:
              "Для руководителя производства важно использовать свои эмоции и рационально расходовать ресурсы. Данному кандидату способность использовать свои эмоции дается не так хорошо, как другие процессы, и, несмотря на высокий балл по общему ЭИ, ему лучше подойдет какая-нибудь другая должность. ",
          },
        ],
      },
    ]);
    const result = ref([]);
    const selectedCandidate = ref(null);
    const step = ref(0);

    const getListPosition = computed(() => {
      let result = [];
      for (var i = 0; i < data.value.length; i++) {
        result.push(data.value[i].POSITION.replace(" ", "\u00A0"));
      }
      return result;
    });
    const getCurrentPosition = computed(() => data.value[step.value].POSITION);
    const getCurrentCards = computed(() => data.value[step.value].ITEMS);
    const getAnswer = computed(() => ({
      IS_CORRECT: selectedCandidate.value.IS_CORRECT,
      ANSWER: selectedCandidate.value.ANSWER,
    }));

    const startGame = () => {
      if (data.value.length == 0) {
        alert("Ошибка!!!");
        return false;
      }

      gameRunning.value = true;
      result.value = [];
    };

    const endGame = () => {
      gameRunning.value = false;
      gameOver.value = false;
    };

    const selectCandidate = (item) => {
      result.value.push({
        POSITION: getCurrentPosition.value,
        IS_CORRECT: item.IS_CORRECT,
      });

      selectedCandidate.value = item;
      showAnswer.value = true;
    };

    const nextStep = () => {
      if (step.value == data.value.length - 1) {
        step.value = 0;
        gameOver.value = true;
      } else {
        ++step.value;
      }

      showAnswer.value = false;
    };

    return {
      gameRunning,
      gameOver,
      showAnswer,
      data,
      result,
      selectedCandidate,
      step,
      startGame,
      nextStep,
      selectCandidate,
      endGame,
      getListPosition,
      getCurrentPosition,
      getAnswer,
      getCurrentCards,
    };
  },
};
</script>

<style lang="css" scoped>
/* .slide-right-enter-active,
.slide-right-leave-active {
  transition: all 0.9s ease-out;
}

.slide-right-leave-to,
.slide-right-enter-from {
  opacity: 0;
  transform: translateX(-1000px);
} */

.slide-right-enter-active {
  transition: all 0.2s ease;
}

.slide-right-leave-active {
  transition: all 0.2s ease;
}

.slide-right-enter-from {
  opacity: 0;
  transform: translateX(50px);
}

.slide-right-leave-to {
  opacity: 0;
  transform: translateX(-50px);
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}
.container {
  width: 100%;
  display: flex;
  justify-content: center;
  /*padding-left: 15px;*/
  margin-right: auto;
  margin-left: auto;
}

.candidates {
  display: -ms-flexbox;
  display: flex;
  max-width: 1140px;
  margin: 0 auto;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  min-height: 759px;
  border-radius: 10px;
  background-color: #edf2f8;
  width: 100%;
  overflow: hidden;
}

@media (max-width: 768px) {
  .candidates {
    padding-top: 0px;
    padding-bottom: 0px;
    min-height: auto;
  }

  .container {
    padding: 0;
  }
}
</style>

<style>
.game-card {
  margin: 0 20px;
  background-color: white;
  box-shadow: 0px 4px 20px rgba(182, 196, 214, 0.5);
  border-radius: 10px;
  padding: 25px 0;
}
.candidates__title {
  color: #2d2f40;
  font-size: 25px;
  font-weight: 300;
  font-style: normal;
  line-height: 1.4;
}

.candidates__title--green {
  color: #55cb28;
}

@media (max-width: 768px) {
  .game-card {
    margin: 0;
    box-shadow: none;
    background-color: transparent;
  }
}

@media (min-width: 768px) {
  .candidates__title {
    font-size: 28px;
  }
}

@media (min-width: 992px) {
  .candidates__title {
    font-size: 32px;
  }
}

@media (min-width: 1200px) {
  .candidates__title {
    font-size: 36px;
  }
}

.candidates__text {
  color: #2d2f40;
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  line-height: 1.5;
  padding: 0 15px;
}

@media (min-width: 768px) {
  .candidates__text {
    font-size: 19px;
  }
}

@media (min-width: 992px) {
  .candidates__text {
    font-size: 21px;
  }
}

@media (min-width: 1200px) {
  .candidates__text {
    font-size: 24px;
  }
}
</style>